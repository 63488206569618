<template>
    <div>
        <h3 class="my-2">Trade and Collection Accounts</h3>
        <v-card flat>
            <v-card-text>
                <v-row>
                    <template
                        v-for="({ title, stat, icon, count }, index) in stats"
                    >
                        <v-col cols="4" :key="index">
                            <psi-stats-card
                                hide-actions
                                :title="title"
                                :stat="stat"
                                :color-option="index + 1"
                                :icon="icon"
                                :counter="count"
                            ></psi-stats-card>
                        </v-col>
                    </template>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="accent" class="mr-2" @click.stop="dialog = true">
                    <v-icon class="mr-2">mdi-card-account-details</v-icon>View
                    Details
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="dialog" max-width="960">
            <psi-dialog
                v-if="dialog"
                title="Account Details"
                hide-actions
                @close="dialog = false"
            >
                <credit-collection-accounts
                    v-if="hasCollections"
                    :collections="collection.details"
                ></credit-collection-accounts>
                <credit-trade-accounts
                    v-if="hasTrades"
                    :trades="trade.details"
                ></credit-trade-accounts>
            </psi-dialog>
        </v-dialog>
    </div>
</template>
           
<script>
export default {
    name: "credit-accounts",
    components: {
        CreditCollectionAccounts: () =>
            import(
                "@components/screen/components/Credit/CreditCollectionAccounts"
            ),
        CreditTradeAccounts: () =>
            import("@components/screen/components/Credit/CreditTradeAccounts"),
    },
    props: {
        collection: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
        trade: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            dialog: false,
        };
    },
    computed: {
        hasCollections() {
            return this.$_.get(this.collection, "details", []).length > 0;
        },
        hasTrades() {
            return this.$_.get(this.trade, "details", []).length > 0;
        },
        stats() {
            let stats = [];
            if (
                this.$_.get(this.collection, "total", "") &&
                !isNaN(this.$_.get(this.collection, "total", ""))
            ) {
                stats.push({
                    icon: "mdi-file-phone",
                    title: `Collection Accounts`,
                    count: `${this.collection.details.length}`,
                    stat: "Due: " + this.$dollarFormat(this.collection.total),
                });
            }
            if (
                this.$_.get(this.trade, "pastDueTotal", "") &&
                !isNaN(this.$_.get(this.trade, "pastDueTotal", ""))
            ) {
                const pastDueAccounts = this.trade.details.filter(
                    (item) => this.$_.get(item, "amountPastDue", 0) > 0
                ).length;
                stats.push({
                    icon: "mdi-credit-card-refresh",
                    title: `Trade Accounts`,
                    count: `${pastDueAccounts}`,
                    stat: "Due: " + this.$dollarFormat(this.trade.pastDueTotal),
                });
            }
            if (
                this.$_.get(this.trade, "revolvingTotal", "") &&
                !isNaN(this.$_.get(this.trade, "revolvingTotal", ""))
            ) {
                const revolvingAccounts = this.trade.details.filter(
                    (item) => this.$_.get(item, "amountPastDue", 0) == 0
                ).length;
                stats.push({
                    icon: "mdi-credit-card-refresh",
                    title: `Trade Accounts`,
                    count: `${revolvingAccounts}`,
                    stat: this.$dollarFormat(this.trade.revolvingTotal),
                });
            }
            return stats;
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>