var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", { staticClass: "my-2" }, [
        _vm._v("Trade and Collection Accounts")
      ]),
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _vm._l(_vm.stats, function(ref, index) {
                    var title = ref.title
                    var stat = ref.stat
                    var icon = ref.icon
                    var count = ref.count
                    return [
                      _c(
                        "v-col",
                        { key: index, attrs: { cols: "4" } },
                        [
                          _c("psi-stats-card", {
                            attrs: {
                              "hide-actions": "",
                              title: title,
                              stat: stat,
                              "color-option": index + 1,
                              icon: icon,
                              counter: count
                            }
                          })
                        ],
                        1
                      )
                    ]
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: { color: "accent" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.dialog = true
                    }
                  }
                },
                [
                  _c("v-icon", { staticClass: "mr-2" }, [
                    _vm._v("mdi-card-account-details")
                  ]),
                  _vm._v("View Details ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "960" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _vm.dialog
            ? _c(
                "psi-dialog",
                {
                  attrs: { title: "Account Details", "hide-actions": "" },
                  on: {
                    close: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [
                  _vm.hasCollections
                    ? _c("credit-collection-accounts", {
                        attrs: { collections: _vm.collection.details }
                      })
                    : _vm._e(),
                  _vm.hasTrades
                    ? _c("credit-trade-accounts", {
                        attrs: { trades: _vm.trade.details }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }